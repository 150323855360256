#fixed-outlet-detail {
  position: fixed;
  width: 100vw !important;
  background-color: #fff;
  z-index: 999;
  top: 0;
}

.fixed-category {
  position: fixed;
  width: 100vw;
  top: 92px;
  z-index: 99999;
}

div.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  font-size: 20px;
}

.sticky-overflow {
  max-height: calc(100vh - 140px);
  overflow-y: scroll;
}

.floating-backBtn {
  position: fixed;
  border-radius: 50%;
  background: #80808054;
  left: 1.5%;
  top: 1.5%;
}

.floating-closeBtn {
  position: fixed;
  border-radius: 50%;
  background: #cdcdcd54;
  left: 1.5%;
  top: 1.5%;
}
